
$primary-color: #fe8019;
$height: 100%;
.logo{
width: 120px;
height: 31px;
margin: 16px 28px 16px 0;
float: left;
}
.ant-tabs-content{
  flex:1;
  overflow:hidden;
  }
.layout-container-antd{
  overflow:hidden
}
body {
    background-color: #f9f9f9;
    overflow:hidden;
}
#sider{
  height:$height;
}

#root {
  height:$height;
  overflow: hidden;

}
#root > div{height:$height;}

.Toastify__toast {
  height: auto!important;
}

.Toastify__toast-container
{height: initial!important;}

.Toastify{
  height:initial!important;
}
.primary-button{
  position:relative;
  left:0;
  margin:5px;
  border-radius: 3px;
  background-color: #a8a8a8;
  border:none;

  }
  .primary-button:hover{
    border-radius: 3px;
    background-color: #909090;
    border:none;
  }

.titulo{
    font-family: HelveticaNeue;
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5c5c5c;

}
.tab-text{
    font-family: HelveticaNeue;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808080;
}
.tab-text-selected{
    font-family: HelveticaNeue;
font-size: 14px;
font-weight: bold;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: normal;
color: #5c5c5c;
}
