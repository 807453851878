.title{
width: 131px;
height: 35px;
font-family: HelveticaNeue;
font-size: 30px;
font-weight: 300;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: normal;
color: #5c5c5c;
}
.titleHeader{

height: 35px;
font-family: HelveticaNeue;
font-size: 30px;
font-weight: 300;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: normal;
color: #5c5c5c;
}
.subtitle{
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c5c5c;
  margin-right:5px;
}

.background{
    background-color: #f2f2f2;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

#submitButton{
    width:100%;
}
