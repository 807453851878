                .gantt-container{
                  height:100%;
                  min-height:500px;
                  width:100%;
                  overflow-y: auto;
                }
                #contenedor-extra{
                    height:500px;
                }
                .gantt_task_cell.week_end {
                	background-color: #EFF5FD;
                		}

                .gantt_task_row.gantt_selected .gantt_task_cell.week_end {
                	background-color: #F8EC9C;
                		}
                .deadline {
        			position: absolute;
        			//border-radius: 12px;
        			//border: 2px solid #585858;
        			-moz-box-sizing: border-box;
        			box-sizing: border-box;

        			width: 22px;
        			height: 22px;
        			margin-left: -11px;
        			margin-top: 4px;
        			z-index: 1;
        			background: url("./Reloj-01-01.png") center no-repeat;
        		}

        		.overdue-indicator {
        			width: 24px;
        			margin-top: 5px;
        			height: 24px;
        			-moz-box-sizing: border-box;
        			box-sizing: border-box;
        			border-radius: 17px;
        			color: white;
        			background: rgb(255, 60, 60);
        			line-height: 25px;
        			text-align: center;
        			font-size: 24px;
                }
                .gantt_task_line.gantt_dependent_task {
        			background-color: #65c16f;
        			border: 1px solid #3c9445;
        		}

        		.gantt_task_line.gantt_dependent_task .gantt_task_progress {
        			background-color: #46ad51;
        		}

        		.hide_project_progress_drag .gantt_task_progress_drag {
        			visibility: hidden;
        		}

        		.gantt_task_progress {
        			text-align: left;
        			padding-left: 10px;
        			box-sizing: border-box;
        			color: white;
        			font-weight: bold;
        		}
